import Vue from 'vue';
import Meta from 'vue-meta';
import VModal from 'vue-js-modal';
// @ts-ignore
import VueGtag from 'vue-gtag';
// @ts-ignore
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import './gtm.js';
import setupRouter from '@/router';
import store, { enableTestMode, clearState } from '@/store';
import { siteName, platform_apiRoot, GoogleSiteID } from './config';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import '@/css/front/style.css';
import '@/css/front/theme-responsive.css';
// @ts-ignore
import VueSkeletonLoader from 'skeleton-loader-vue/dist/skeleton-loader-vue.min.js';
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.component('pagination', require('vue-pagination-2').Pagination);
Vue.directive('sticky', require('vue-sticky').default);
Vue.use(Meta);
Vue.use(require('vue-scrollto'), {
    offset: -100
});
var VueFormWizard = require('vue-form-wizard');
Vue.component('form-wizard', VueFormWizard.FormWizard);
Vue.component('tab-content', VueFormWizard.TabContent);
Vue.component('wizard-button', VueFormWizard.WizardButton);
Vue.component('wizard-step', VueFormWizard.WizardStep);
Vue.use(VModal);
//Vue.use(require('vue-lazyload'))
Vue.use(require('vue2-google-maps'), {
    load: { key: 'AIzaSyC79TDrjmtxTK2nP52JiGQQcjnvtECx1MQ' } // owher of the key is mammothmrbo@gmail.com - Jackie's primary advertising account
    //load: { key: 'AIzaSyCxfeWxpytsglKMghpgW10c3jK8i5HfLjo' } // temp: new key from my personal account, project NomadnessRentals
});
Vue.component('vue-programmatic-invisible-google-recaptcha', VueProgrammaticInvisibleGoogleRecaptcha);
var carousel = require('vue-carousel');
Vue.component('Carousel', carousel.Carousel);
Vue.component('Slide', carousel.Slide);
var router = setupRouter();
var sessionID = null;
var sessionCreatedAt = null;
var referrer = document.referrer;
Vue.use(VueGtag, {
    config: {
        id: GoogleSiteID,
        params: {
            send_page_view: true
        }
    }
}, router);
Vue.use(require('vue-cookies'));
router.beforeEach(function (to, from, next) {
    try {
        var delay = 1000 * 60 * 60 * 4; // 4 hours in miliseconds
        if (sessionCreatedAt + delay <= Date.now() && sessionCreatedAt != null) {
            clearState(store);
            sessionCreatedAt = Date.now();
        }
        if (sessionID == null || sessionCreatedAt == null) {
            sessionCreatedAt = Date.now();
            sessionID = Math.random()
                .toString(36)
                .substr(2, 11);
        }
    }
    catch (_a) {
        // ignore
    }
    //@ts-ignore
    router.sessionID = sessionID;
    //@ts-ignore
    router.referrerID = referrer;
    /* enable test mode vvvvvvvvvvvv */
    if (to.query['enable-test-mode'] == '392F-DECE') {
        enableTestMode(store);
    }
    /* test mode ^^^^^^^^^^^%%%%^^^^ */
    try {
        var userAgent = navigator.userAgent;
        // @ts-ignore
        var urlParams = Object.keys(to.query)
            .map(function (key) { return encodeURIComponent(key) + '=' + encodeURIComponent(to.query[key]); })
            .join('&');
        navigator.sendBeacon("".concat(platform_apiRoot, "/res/api/pageView.cfm?w=").concat(siteName, "&q=").concat(encodeURIComponent(to.path), "&ref=").concat(encodeURIComponent(referrer), "&sid=").concat(encodeURIComponent(sessionID), "&qp=").concat(encodeURIComponent(urlParams), "&ua=").concat(encodeURIComponent(userAgent)));
        referrer = '';
    }
    catch (_b) {
        // ignore
    }
    document.body.style.overflow = '';
    // done - go next
    next();
});
// <script type="application/javascript">
var headerScripts = {
    resortandlodges: { src: 'https://tracking.resortsandlodges.com/tracking.js' }
};
router.afterEach(function (to, from) {
    // first unload the existing script if present
    for (var scriptName in headerScripts) {
        var thisScript = document.querySelectorAll('script[id="script_' + scriptName + '"]');
        for (var i = 0; i < thisScript.length; i += 1) {
            thisScript[i].parentElement.removeChild(thisScript[i]);
        }
    }
    // load the "header" scripts here
    for (var scriptName in headerScripts) {
        var thisScript = document.createElement('script');
        if (headerScripts[scriptName].src) {
            thisScript.setAttribute('src', headerScripts[scriptName].src);
        }
        else {
            thisScript.innerText = headerScripts[scriptName].code;
        }
        document.head.appendChild(thisScript);
    }
});
// eslint-disable-next-line no-new
var vueApp = new Vue({
    el: '#app',
    router: router,
    store: store,
    render: function (h) {
        return h('router-view');
    }
});
// let multiSelectLib = require('vue-multiselect')
import 'vue-multiselect/dist/vue-multiselect.min.css';
// Vue.component('multiselect', multiSelectLib)
Vue.component('Multiselect', require('vue-multiselect').default);
export default vueApp;
